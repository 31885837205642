@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css');


@font-face {
  font-family: 'Stolzl';
  src: url('../fonts/Stolzl-Bold.woff2') format('woff2'),
      url('../fonts/Stolzl-Bold.woff') format('woff'),
      url('../fonts/Stolzl-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stolzl';
  src: url('../fonts/Stolzl-Regular.woff2') format('woff2'),
      url('../fonts/Stolzl-Regular.woff') format('woff'),
      url('../fonts/Stolzl-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stolzl';
  src: url('../fonts/Stolzl-Medium.woff2') format('woff2'),
      url('../fonts/Stolzl-Medium.woff') format('woff'),
      url('../fonts/Stolzl-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.font-test{font-family: 'Poppins', sans-serif;}

/* font-family: 'Montserrat', sans-serif; */
*{padding:0; margin:0; border:none; -moz-box-sizing:border-box; -ms-box-sizing:border-box; -webkit-box-sizing:border-box; box-sizing:border-box; }
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code, 
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {display: block;}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
strong, b{font-weight:bold}
em, i{font-style:italic;}
u{text-decoration:underline}
a{text-decoration: none;}
*:focus{outline: none;}
a:hover, a:focus {text-decoration: none; outline: none !important;}
button[type="button"]:focus{outline: none;}


/* 1.1 SVG default css*/
svg:not(:root).svgImg { overflow: visible }
.svgImg { display: inline-block; font-size: inherit; width:1em; height: 1em; overflow: visible; vertical-align: -.125em; }
.svg-icons { display: inline-block; font-size: inherit; height: 1em; overflow: visible; vertical-align: -.125em }
/*SVG default css*/

img{display: block; max-width:100%; max-height:100%; width:auto; height:auto}
p{line-height:1.6;font:400 2.4rem/1.2 'Poppins', sans-serif;color:#000000; margin-bottom:1rem;}
p:last-child{margin-bottom:0;}
a{color: #3ba1ff; transition: color 0.4s ease-in-out, transform 0.4s ease-in-out;}
a:hover{color: #ffffff;}

.c-scrollbar{
  height: 100vh !important
}

.bg-primary{background: #4a74fe}
.bg-dark{background: #000;}

html{font-size: 0.525vw;}
body{display:block; width:100%; padding:0; margin:0; font-family: 'Montserrat', sans-serif; font-size: 1.6rem; font-weight:400; line-height:1.4; color:#000000; background-color:#fff; position: relative;}
.trans{-moz-transition:all 0.4s ease-in-out; -ms-transition:all 0.4s ease-in-out; -webkit-transition:all 0.4s ease-in-out; transition:all 0.4s ease-in-out;}
.wrapper{display:block; width:100%; overflow:hidden; min-height: 100vh; position: relative;}
.container{max-width: 157rem;}
p{display: block;font-size: 1.6rem;font-weight: 500;color: #ffffff;margin-bottom: 1.6rem;line-height: 1.3;}

.commonSec {padding: 16.6rem 15.6rem;}

.container-fluid {padding-left: 14rem; padding-right: 14rem;}
.ant-form div.ant-form-item .ant-form-item-control-input-content>.ant-input-affix-wrapper .ant-input{font-size: 13px !important; font-weight: normal !important;}

/* Buttons */
.btn{display: inline-block !important; width: auto !important; min-width: 22.6rem !important; background: #000 !important; border: 0.5rem solid #000 !important; border-radius: 60rem !important; padding: 2.6rem 2.5rem !important; font-family: 'Montserrat', sans-serif !important;  font-size: 1.6rem !important; font-weight: 700 !important; color: #fff !important;
text-transform: uppercase !important; transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out, transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out !important;}
.btn.btn-white{background: #fff !important; border-color: #fff !important; color: #000 !important;}
.btn.btn-outline{background: transparent !important; color: #000 !important;}
.btn.btn-outline.btn-white{color: #fff !important;}

.btn:hover {color: #fff !important; background: #333 !important; border-color: #333 !important;}

.btn.btn-blue {background: #00aeef !important; border-color: #00aeef !important;}
.btn.btn-blue:hover {background: #000 !important; border-color: #000 !important;}

.btn.btn-red {background: #db2738 !important; border-color: #db2738 !important;}
.btn.btn-red:hover {background: #000 !important; border-color: #000 !important;}


/* Header */
.headerMain{display: block;width: 100%;padding: 2.9rem 9.7rem; position: absolute; left:0;top:0; z-index: 9;}
.headerMain .container-fluid {padding: 0;}
.head-btn {padding: 1.9rem 3.4rem !important; min-width: inherit !important;}
.topCall{display: inline-block; vertical-align: middle; width: auto; font-size: 2.5rem; font-weight: 700; color: #000; padding: 1.8rem 2.5rem;}
.topCall i{display: inline-block; vertical-align: middle; margin-right: 1.2rem;}
.topCall:hover {color: #666;}

/* Page Head */
.pageHead{display: block; width: 100%; margin-top: 14.6rem;}
.secHead .secTitle{display: block; width: 100%; font-size: 11.6rem;  line-height: 0.9; font-family: 'Stolzl'; font-weight:bold; color: #ffffff; margin-bottom: 3.6rem;}
.secHead .SecondTitle {display: block; width: 100%; font-size: 6.8rem; line-height:1; font-family: 'Stolzl'; font-weight:bold; color: #ffffff; margin-bottom: 5rem;}

.topSec {position: relative;}

.bannerSec {background: url('../images/banner-img.jpg') no-repeat center; height: 98rem; background-size:cover; position: relative;}
.bannerSec:before{content: ''; position: absolute; left: 0; top: 0; right: 0; bottom: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.8);}
.bannerCaption {width: 100%; position: relative; max-width: 89.2rem; padding-top: 27rem; padding-left: 15.6rem;}
.bannertitle{font-size: 10.6rem; line-height:1; font-family: 'Stolzl'; font-weight:bold; color: #ffffff; margin-bottom:4rem;}
.bannerDesc {color: #ffffff; max-width: 57.8rem;}

.vehicleSec .container-fluid{padding-left: 0; padding-right: 0;}
.vehicle-col {padding-left: 0; padding-right: 0;}

.vehicleInfoImg {position: relative; padding-bottom: 97.8rem;}
.vehicleInfoImg img {position: absolute; left: 0; top: 0; bottom: 0; width: 100%; height: 100%; object-fit: cover; object-position: center;}
.vehicleInfo {background:#1c1c24; width: 50rem; padding:6rem 6.8rem 5rem; position: absolute; right:14.6rem; top:21rem;}

.formInfo {max-width: 100%;}
.frmTitle { text-align: center; font-size: 2.4rem; font-family: 'Stolzl'; font-weight:bold; color: #ffffff; margin-bottom: 4rem; line-height: 1.41;}

.formcol {margin-bottom: 2.4rem; position: relative;}
.formError{display: block;font-size: 12px;font-weight: 300;padding: 5px 0 0 30px;color: #ff0000;font-family: 'Poppins', sans-serif;}
.formError:first-letter {text-transform: uppercase;}
.inputformField,.inputformSelect {position: relative; padding: 1.5rem 3rem; width: 100%; min-height: 6.1rem; margin-bottom: 0; vertical-align: middle;
    border:0; text-align: left; transition: border 0.4s ease; border-radius: 6rem;
    font-family: 'Stolzl'; font-size: 1.5rem; font-weight: 500; color: #000; -webkit-appearance: none;
   -moz-appearance:none; appearance:none;}
.inputformField::placeholder{color: #000 !important;}
.inputformField:focus {border-color: #000 !important;}

/* .inputformField.is-invalid{} */

.custom_drop {background: #fff url(../images/svg/custom-drop.svg) no-repeat right 3.4rem center; -webkit-appearance: none; background-size: 1.4rem;}

.formbtn {margin-top: 1rem; display:block;}
.formbtn .btn{padding: 1.4rem 2.5rem !important; width: 100% !important;}

.forgot_pass {font-family: 'Stolzl'; font-size: 1.5rem; font-weight: 500; color: #3ba1ff; display: block; margin-top: 0.5rem; margin-left: 3rem; transition: color 0.4s ease-in-out, transform 0.4s ease-in-out;}
.forgot_pass:hover {color: #ffffff;}

.form_account {margin-top: 2.8rem; padding-top: 2.8rem; padding-left: 3rem; border-top: 0.1rem solid #35353e;}

.accountInner {font-family: 'Stolzl'; font-size: 1.8rem; font-weight: 500; color: #ffffff;}
.accountInner a {color: #3ba1ff; transition: color 0.4s ease-in-out, transform 0.4s ease-in-out;}
.accountInner a:hover {color: #ffffff;}

.wpcf7-form-control-wrap {
  position: relative;
  display: block;
}
label.error { 
  display: none;
  position: absolute;
  right: 0;
  bottom: 100%;
  height: auto;
  margin: 0;
  margin-bottom: -3px;
  font-size: 1.1rem!important;
  line-height: 1.1rem!important;
  background: #fb0101;
  padding: 0.5rem 0.5rem 0.4rem!important;
  color: #fff!important;
}
label.error:after{
  border-color: #fb0101 transparent transparent;
  -o-border-image: none;
  border-image: none;
  border-right: 0.6rem solid transparent;
  border-style: solid;
  border-width: 0.6rem;
  content: "";
  height: 0;
  left: 0.5rem;
  position: absolute;
  top: 100%;
  width: 0;
}


.darkgrey_bg {background: #13131a;}
.lightgrey_bg {background: #1c1c24;}

.ContentCon {max-width: 67.5rem;}
.carImg {position: relative; padding-bottom:64.8rem; max-width: 76.7rem;}
.carImg img {position: absolute; left: 0; top: 0; right: 0; bottom: 0; width: 100%; height: 100%; object-fit: cover; object-position: center;}

.rightImg {margin-left: auto;}

.Conleft {padding-left: 8rem;}

.ContentCon .btn{margin-top: 3rem !important;}

.perfectSec .secHead .SecondTitle{font-size: 10rem;}

.perfectCon {padding-left: 6rem;}

.perfectCon ul{font-size: 0;}
.perfectCon ul li{width: 50%; padding: 0 4.5rem; display: inline-block; vertical-align: top; border-left: .2rem solid #35353e;}
.perfectCon ul li:first-child{padding-left:0; border-left:0;}

.perfectCon ul li p { position: relative; padding-left: 1.6rem; display: block; font-family: 'Stolzl'; font-size: 2rem; font-weight: bold; color: #fff; margin-bottom:1rem;}
.perfectCon ul li p:before {content: ''; position: absolute; left: 0; top:1.2rem; width: 7px; height: 3px; background: #35353e;}

.becomeSec {padding: 12.2rem 15.6rem 11rem;}
.becomeInner {text-align: center;}
.becomeInner p {font-size: 2.4rem;}
.becomeInner .btn {margin-top: 4rem !important;}

.footerMain {background:#1c1c24;}
.copyright {text-align: center; color: #fff; font-size: 1.6rem; padding-bottom: 4rem;}
.copyright a {color: #fff; transition: color 0.4s ease-in-out, transform 0.4s ease-in-out;}
.copyright a:hover {color: #999;}


.simpleSec {padding: 19.1rem 15.6rem;}
.simpleSec .secHead .secTitle {font-size: 10.6rem;}
.simpleInner {text-align: center;}
.simpleInner p {max-width: 103.6rem; margin: auto;}
.simpleInner .btn {margin-top: 4rem !important;}

.steplist {padding: 0; font-size: 0; margin: 9rem 0;}
.steplist li{padding:0 9rem; display: inline-block; width: 33.3%; vertical-align: text-top;}

.stepBox {padding: 0;}
.stepNumber { padding-right: 2rem; margin-right: 2rem; border-right:.2rem solid #35353e; font-family: 'Stolzl'; color: #ffffff; font-size: 10rem; line-height: 1; font-weight: bold; display: inline-block; vertical-align: middle;}
.stepTitle {font-family: 'Stolzl'; color: #ffffff; font-size: 2rem; font-weight: bold; display: inline-block; vertical-align: middle;}


/* register page */

.register_banner {padding:4.2rem 0; height: 100vh; background: url('../images/register_banner.jpg') no-repeat center; background-size:cover; position: relative;}

.register_banner:before{content: ''; position: absolute; left: 0; top: 0; right: 0; bottom: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.8);}

.register_banner .vehicleInfo{position: relative; right:inherit; top:50%; transform: translateY(-50%); margin: 0 auto; width: 56.6rem; padding: 5rem 9.9rem;}

.emaillogo {width: 14.7rem; margin: auto; display: block; margin-bottom: 2rem;}

.register_banner .formcol {margin-bottom: 1.6rem;}

.register_banner .frmTitle {margin-bottom: 3rem;}


.customCheckbox{position: relative;}
.customCheckbox input[type="checkbox"]{position: absolute; left: 0; opacity: 0; top: 0; left: 0;} 
.customCheckbox > .lable-text{display: block; width: 100%; position: relative; padding-left: 25px; cursor: pointer; color: #ffffff; font-size: 1.5rem; font-family: 'Stolzl'; font-weight: normal;}
.customCheckbox > .lable-text:before{content: ""; position: absolute; left: 0; top:4px; margin: auto;  width: 14px; height: 14px; border:0; background: #ffffff; border-radius:0;} 
.customCheckbox > .lable-text:after{content: ""; -webkit-transform: translate(4px,5.38px) rotate(-45deg); transform: translate(4px,5.38px) rotate(-45deg); width: 9px; height: 5px; border: 2px solid #fff; border-top-style: none; border-right-style: none; opacity: 0; transition: all .4s ease-in-out; position: absolute; left:-2px; top: 2px; margin: auto;} 
.customCheckbox.darkCheck > .lable-text:after{border-color: #28304b;}
.customCheckbox input[type="checkbox"]:checked+.lable-text:before { background-color: #db2738; border-color: #db2738;}
.customCheckbox input[type="checkbox"]:checked+.lable-text:after { opacity: 1;}

.view {position: absolute; right:2.9rem; top:2.2rem; width: 2.5rem; z-index: 3;}


