
.cbody{
  max-height:500px;
  overflow:auto;
}

tr {
  border-bottom: 1pt solid white;
}

table { 
  border-collapse: separate;
  border-spacing: 5px;
}

.termsPopup td {
  padding: 15px !important;
}

.ant-form-item-control-input-content{
  color:white;
  margin-left:5px;
}

.ant-checkbox-wrapper {
  margin-right:12px !important;
}
