.ant-form {
  .ant-form-item {
    position: relative;

    .ant-form-item-label {
      padding: 0;
      margin: 0 5px;
      position: absolute;
      top: 11px;
      transform-origin: left;
      transition: all 0.4s;
      user-select: none;
      z-index: 10;

      > label {
        color: var(--color-text-alt);
        line-height: 20px;
        padding: 0 5px;
      }
    }

    .ant-form-item-control-input-content {

      > .ant-input,
      > .ant-input-affix-wrapper {
        background: var(--background-alt);
        border: 1px solid var(--background-alt);
        border-radius: var(--radius-input);
        box-shadow: none;
        color: var(--color-text-base);
        line-height: 24px;
        padding-bottom: 8px;
        padding-top: 8px;
        transition: background 0.4s, color 0.4s, border 0.4s;

        .ant-input {
          background: var(--background-alt);
          color: var(--color-text-base);
          box-shadow: none;
        }
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      box-shadow: 0 0 0 30px var(--background-body) inset !important;
      -webkit-text-fill-color: var(--color-text-base) !important;
      transition: none;
    }

    &:focus-within,
    &.has-value {
      .ant-form-item-label {
        background: var(--background-body);
        transform: scale(0.75) translateY(-30px) translateX(5px);
      }

      .ant-form-item-control-input-content {

        > .ant-input,
        > .ant-input-affix-wrapper {
          background: var(--background-body);
          border-color: var(--background-alt);

          .ant-input {
            background: var(--background-body);
          }
        }
      }

      .ant-select .ant-select-selector,
      select {
        background: var(--background-body);
        border-color: var(--background-alt);
      }
    }

    select {
      outline: none;
      width: 100%;
    }

    .ant-select .ant-select-selector,
    select {
      background: var(--background-alt);
      border: 1px solid var(--background-alt);
      border-radius: var(--radius-input);
      box-shadow: none;
      color: var(--color-text-base);
      height: 42px;
      line-height: 24px;
      padding-bottom: 8px;
      padding-top: 8px;
      transition: background 0.4s, color 0.4s, border 0.4s;

      .ant-select-selection-item {
        line-height: 24px;
        height: 24px;
      }
    }

    &.ant-form-item-has-error {
      .ant-form-item-control-input-content {

        > .ant-input,
        > .ant-input-affix-wrapper {
          border-color: var(--utility-error);
        }
      }

      &:focus-within {
        .ant-form-item-label {
          > label {
            color: var(--color-text-error);
          }
        }
      }
    }
  }

  .ant-form-item-explain,
  .ant-form-item-extra {
    font-size: 12px;
  }
}

.ant-drawer-content {
  .ant-form {
    .ant-form-item {
      .ant-form-item-control-input-content {

        > .ant-input,
        > .ant-input-affix-wrapper {
          border-color: var(--color-text-alt);
        }
      }

      &:focus-within,
      &.has-value {
        .ant-form-item-control-input-content {

          > .ant-input,
          > .ant-input-affix-wrapper {
            border-color: var(--color-text-alt);
          }
        }
      }

      .ant-select .ant-select-selector,
      select {
        border-color: var(--color-text-alt);
      }
    }
  }
}

body {
  a.ant-btn {
    padding-top: 4px !important;
  }

  .ant-btn,
  .ant-btn[disabled] {
    align-items: center;
    border: 0 !important;
    border-radius: var(--radius-input);
    box-shadow: none;
    color: var(--color-text-base);
    display: flex;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    height: 38px;
    justify-content: center;
    letter-spacing: 0.1px;
    line-height: 30px;
    padding: 4px 10px !important;

    &:hover,
    &:focus,
    &:active {
      color: var(--color-text-base);
    }

    svg {
      margin-right: 8px;
    }

    &.button-large-text {
      font-size: 14px;
    }
  }

  .ant-btn.button-primary,
  .button-primary > .ant-btn[disabled] {
    background: var(--brand-red);

    &:hover {
      background: #DC2335;
    }

    &:focus,
    &:active {
      background: #A71A28;
    }
  }

  .ant-btn.button-secondary {
    background: var(--background-alt-2);

    &:hover {
      background: #535365;
    }

    &:focus,
    &:active {
      background: #373743;
    }
  }

  .ant-btn.button-alt {
    background: var(--utility-background);

    &:hover {
      background: #297BFF;
    }

    &:focus,
    &:active {
      background: #005AEB;
    }
  }
}

.manual-form-error {
  margin-bottom: 8px;
}

.common-referral-user-form {
  margin: 15px 0 30px;
}