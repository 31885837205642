/* css only for hover effect all hover effect must write in this Media query */
@media only screen and (max-width: 1920px) {


}

@media only screen and (min-width: 1200px) {

}



@media only screen and (max-width: 1660px) {

}


@media only screen and (min-width: 768px) {

}


@media only screen and (max-width: 1599px) {

p {font-size: 2.4rem;}
.btn {font-size: 2rem !important;}
.stepTitle {font-size: 2.4rem;}
.steplist li{padding: 0 3rem;}

}





@media only screen and (max-width: 1366px) {


}

@media only screen and (max-width: 1280px) {

}

 

/* common css for below screen 1199 */

@media only screen and (max-width: 1199px) {

.headerMain {padding: 2.5rem 4.4rem;}
.secHead .secTitle {font-size: 8rem;}
.siteLogo img {max-width: 36.0rem;}

.inputformField {font-size: 2rem;}
.vehicleInfo {padding: 6rem 4.8rem 5rem;}
.forgot_pass, .accountInner {font-size: 2rem;}


.register_banner .vehicleInfo {padding: 5rem 7.9rem; width: 64.6rem;}
.emaillogo {width: 17.7rem;}
.customCheckbox > .lable-text {font-size: 2rem;}

.view {width: 18px;}


}


/* common css for below screen 991 */

@media only screen and (max-width: 991px) {
html{font-size: 0.677vw}
p {font-size: 14px;}

.container {padding: 0 5rem;}

.btn {font-size: 12px !important; padding: 12px 20px !important;}

.bannertitle {font-size: 8.6rem;}

.bannerCaption {padding-left: 4.6rem; max-width: 66.2rem;}

.vehicleInfo {right: 4.6rem;}

.commonSec {padding: 11.6rem 5.6rem;}

.ContentCon {max-width: 60.5rem;}

.carImg {padding-bottom: 56.8rem;}

.simpleSec .secHead .secTitle, .perfectSec .secHead .SecondTitle {font-size: 8.6rem;}

.stepTitle {font-size: 14px;}

.steplist li {padding: 0;}

.perfectCon ul li p {font-size: 14px; padding-left: 12px;}

.copyright, .becomeInner p {font-size: 14px;}

}



/* common css for below screen 767 */

@media only screen and (max-width: 767px) {
html{font-size: 5px}
p {font-size: 14px; line-height: 1.4;}
.headerMain{padding: 4rem 3rem 0 3rem;}
.siteLogo img {max-width: 31rem;}

.commonSec {padding: 5rem 3rem;}

.bannerSec {height: 450px;}
.bannerCaption {padding-top: 18.6rem; max-width: 66.6rem;}

.bannertitle {font-size: 35px;}

.vehicleInfo {top: 18rem;}

.frmTitle {font-size: 3rem;}

.secHead .SecondTitle {font-size: 30px;}

.carImg {padding-bottom: 52.8rem;}

.simpleSec .secHead .secTitle, .perfectSec .secHead .SecondTitle {font-size: 32px;}

.steplist {margin: 6rem 0;}
.stepNumber {font-size: 9rem;}

.perfectCon ul li {width: 100%; padding: 0; border: 0;}

.simpleInner p {max-width: 100%;}

.stepTitle {font-size: 15px;}

.register_banner {height: 100%;}

.register_banner .vehicleInfo {transform: none;}

.emaillogo {width: 120px;}

.inputformField {font-size: 14px; padding: 12px 20px;}

.view {width: 22px; top: 18px;}

.perfectCon ul li p:before {top: 8px;}

}


@media only screen and (max-width: 568px) {


}


/* common css for below screen 479 */

@media only screen and (max-width: 479px) {

/*.headerMain {padding: 4rem 2rem 0 2rem;}*/
.bannerSec { height:calc(100vh - 40px); background: url('../images/m-banner-img.jpg') no-repeat center; background-size: contain; background-position: bottom;background-color: white;}

.bannerSec:before{
  background: rgba(0, 0, 0, 0.72);
/* background: -moz-linear-gradient(top,  rgba(0,0,0,0.80) 0%, rgba(0,0,0,0) 100%);
background: -webkit-linear-gradient(top,  rgba(0,0,0,0.80) 0%,rgba(0,0,0,0) 100%);
background: linear-gradient(to bottom,  rgba(0,0,0,0.80) 0%,rgba(0,0,0,0) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); */
}

.bannerCaption {padding-top: 100px;}

.btn {font-size: 14px !important; padding: 19px !important; min-width: 125px !important;}

.bannerCaption {max-width: 350px; padding-left: 34px; padding-right: 15px;}

.bannertitle {font-size: 35px;}
.secHead .SecondTitle {font-size: 26px; line-height: 1.2; margin-bottom: 15px;}
.secHead .secTitle {font-size: 28px;}
.bannerCaption .btn{margin-top: 6px; letter-spacing: 1px;}

.vehicleInfo {position: static; width: 100%;}

.frmTitle {font-size: 18px;}

.inputformField {font-size: 14px; padding: 15px 20px;}

.formbtn .btn {padding: 12px !important; font-size: 14px !important;}

.forgot_pass, .accountInner {font-size: 14px;}

.accountInner {text-align: center;}

.ContentCon {max-width: 100%; margin-top: 20px;}

.Conleft {padding-left:0;}

.carImg {padding-bottom: 295px;}

.perfectCon {padding-left: 0; margin-top: 20px;}

.stepBox {text-align: center;}

.stepNumber {padding:0 20px 5px; font-size: 10rem; margin-bottom: 5px; margin-right: 0; border-right: 0; /*border-bottom: 1px solid #35353e;*/}

.stepTitle {font-size: 16px; display: block;}

.register_banner .vehicleInfo {padding: 5rem 4.9rem;}

.customCheckbox > .lable-text {font-size: 14px;}


.steplist li {padding: 0; width: 100%; margin-top: 5rem;}
.steplist li:first-child {margin-top:0;}



@media only screen and (max-width: 380px) {

}


@media only screen and (max-width: 350px) {


}


/* common css for screen size between 480 to 767 */

@media only screen and (min-width: 480px) and (max-width: 767px) {

}


/* common css for screen size between 568 to 767 */

@media only screen and (min-width: 568px) and (max-width: 767px) {

}


/* common css for screen size between 600 to 767 */

@media only screen and (min-width: 600px) and (max-width: 767px) {

	
}



/* common css for screen size between 768 to 1199 */

@media only screen and (min-width: 768px) and (max-width: 1199px) {


}


/* common css for screen size between 768 to 1023 */

@media only screen and (min-width: 768px) and (max-width: 991px) {


}

/* common css for screen size between 1024 to 1199 */

@media only screen and (min-width: 1200px) and (max-width: 1366px) {

}	

@media only screen and (max-width:600px) {
  /* .topSec{height:100vh;} */
  .bannerSec { height:100vh; background: url('../images/m-banner-img.jpg') no-repeat center; background-size: cover; background-position: bottom;background-color: white;}
  .bannerCaption{padding-top:0px;transform:translateY(-66%);top:50%}
  }
}