.common-user-menu {
  align-items: center;
  display: flex;

  span {
    color: var(--color-text-base);
    font-family: var(--font-poppins);
    font-weight: 600;
    font-size: 14px;
    margin-right: 8px;
  }

  &-mobile-user {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
@media only screen and (max-width: 992px) {
}
