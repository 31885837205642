.common-form-splash {
  background: var(--background-body);
  max-width: 360px;
  padding: 36px var(--space-box);
  width: 100%;

  &.has-footer {
    padding-bottom: 0;
  }

  &-footer {
    background: var(--background-alt);
    border-radius: 0 0 var(--radius-main) var(--radius-main);
    color: var(--color-text-base);
    height: 48px;
    line-height: 48px;
    margin: 24px -20px 0;
    text-align: center;
  }

  &-title {
    color: var(--color-text-base);
    font-family: var(--font-poppins);
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin: 24px 0 34px;
    text-align: center;

    &:first-child {
      margin-top: 0;
    }
  }
}