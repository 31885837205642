:root {
  --background-alt: #292932;
  --background-alt-2: #40404E;
  --background-base: #13131A;
  --background-body: #1C1C24;
  --brand-red: #BF1E2E;
  --brand-red-hover: #DC2335;
  --brand-red-active: #A71A28;
  --color-text-alt: #92929D;
  --color-text-alt-lighten: #B5B5BE;
  --color-text-base: #FAFAFB; 
  --color-text-blue: #50B5FF;
  --color-text-dim: #696974;
  --color-text-error: #FC5A5A;
  --color-text-link: #50B5FF;
  --color-text-link-hover: #85CBFF;
  --color-text-success: #3DD598;
  --color-text-warn: #FF974A;
  --utility-background: #0F6BFF;
  --utility-error: #FC5A5A;

  --font-poppins: 'Poppins', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-roboto: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-Stolzl: 'Stolzl', sans-serif;
  --radius-input: 10px;
  --radius-small: 10px;
  --radius-main: 20px;

  --space-1: 10px;
  --space-1_5: 15px;
  --space-0_8: 8px;
  --space-2: 20px;
  --space-36: 36px;
  --space-5: 50px;
  --space-box: 20px;
  --space-box-inverted: -20px;
  --space-gutters: 16px;

  --badge-text-color: #fffefe;
  --bg-color-rejected: #db2738;
  --bg-color-completed: #00ac67;
  --bg-color-pending: #ff984a;
  --bg-color-approved: #35ce90;
  --bg-color-submitted: #0F6BFF;
  --bg-color-consignment: #7800c9;
}