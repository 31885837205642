#svg-menu {
  width: 16px;
  height: 16px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  
  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: var(--color-text-alt);
    border-radius: 2px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }
  
  span:nth-child(1) {
    top: 0px;
  }
  
  span:nth-child(2),
  span:nth-child(3) {
    top: 7px;
  }
  
  span:nth-child(4) {
    top: 14px;
  }
  
  &.open span:nth-child(1) {
    top: 7px;
    width: 0%;
    left: 50%;
  }
  
  &.open span:nth-child(2) {
    transform: rotate(45deg);
  }
  
  &.open span:nth-child(3) {
    transform: rotate(-45deg);
  }
  
  &.open span:nth-child(4) {
    top: 7px;
    width: 0%;
    left: 50%;
  }
}