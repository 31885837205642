.common-drawer {
  &-info {
    &-title {
      color: var(--color-text-alt-lighten);
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }

    &-value {
      color: var(--color-text-base);
      font-size: 14px;
      line-height: 20px;
      margin-bottom: var(--space-2);
    }
  }

  &-section {
    &-header {
      padding-bottom: 16px;
    }
  }
}
.common-drawer-section-header-buttons {
  .ant-btn {
    min-width: 85px;
  }
}
