.user-status-pending {
  color: var(--color-text-warn);
}

.user-status-active {
  color: var(--color-text-success);
}

.user-status-suspended {
  color: var(--color-text-error);
}

.common-table-wrapper {
  width: 100%;
}

.ant-picker {
  width: 100%;
  height: 40px;
  background-color: #292932 !important;
  color: #92929e;
}

.ant-picker-input > input {
  color: white !important;
}

span.ant-picker-suffix {
  color: #92929e;
}
.buyersLastLogin {
  .ant-switch {
    background-color: #292932;
    &.ant-switch-checked {
      background-color: #bf1e2e;
    }
  }
}
.nav-item {
  &:first-child {
    .tab-text-btn {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
  &:last-child {
    .tab-text-btn {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}
.tab-text-btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.19;
  color: #fff;
  padding: 12px 22px;
  font-family: var(--font-poppins);
  transition: all 0.4s ease-in-out;
  background-color: #292932;
  &.active {
    background: var(--background-body);
  }
}
