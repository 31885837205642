.new,
.offer-status-0 {
  background-color: var(--bg-color-submitted);
}

.working,
.offer-status-1 {
  background-color: var(--bg-color-pending);
}

.approved,
.offer-status-2 {
  background-color: var(--bg-color-approved);
}

.rejected,
.offer-status-3 {
  background-color: var(--bg-color-rejected);
}

.completed,
.offer-status-4 {
  background-color: var(--bg-color-completed);
}

.consignment,
.offer-status-5 {
  background-color: var(--bg-color-consignment);
}

.offers-details-data-group {
  + .offers-details-data-group {
    border-top: 1px solid #44444f;
    padding-top: var(--space-2);
  }
}

.button-dark-blue {
  background-color: #595965 !important;
}
.button-dark-blue:hover {
  background-color: #575763 !important;
}
.sendLeadBtn {
  margin-right: 7px;
}
.sendOfferBtn {
  margin-right: 15px;
}
.offers-page-filters {
  margin: 0 0 0 30px;
  @media (max-width: 1460px) {
    margin: 0 0 0 20px;
  }
}
.filter-btn {
  padding: 8px 15px;
  border-radius: 50px;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 600;
  margin: 0 15px 0 0;
  border: 2px solid transparent;
  @media (max-width: 1460px) {
    margin: 0 7px 0 0;
    padding: 6px 10px;
  }
}
.filter-btn.reset {
  border-color: #83818e;
  background-color: transparent;
  color: #83818e;
}
.input-col.deals-search-filter {
  display: flex;
  margin-bottom: 0;
  position: relative;
}
.common-table-wrapper .ant-pagination-disabled a:hover{
  cursor: not-allowed !important;
}

.common-table-wrapper .ant-pagination-item a:hover {
  color: #fffefe !important;
}

.common-table-wrapper .ant-pagination-item a:active {
  color: #fffefe !important;
}

.common-table-wrapper .ant-pagination-next a {
  color: #fffefe !important;
}

.common-table-wrapper .ant-pagination-prev a {
  color: #fffefe !important;
}