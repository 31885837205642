.dashboard-earnings {
  margin-bottom: var(--space-gutters);

  &-history {
    background: var(--background-body);
    // border-radius: var(--radius-main);
    padding: var(--space-box);

    .recharts-cartesian-axis-tick-value {
      font-family: var(--font-roboto);
    }
  }

  &-to-date {
    background: var(--background-body);
    // border-radius: var(--radius-main);
    padding: var(--space-box);

    &.percent-positive {
      .dashboard-earnings-to-date {
        &-percent,
        &-percent-change {
          color: var(--color-text-success);
        }
      }
    }

    &.percent-negative {
      .dashboard-earnings-to-date {
        &-percent,
        &-percent-change {
          color: var(--color-text-error);

          svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    &-amount {
      font-family: var(--font-poppins);
      font-size: 28px;
      font-weight: 600;
      line-height: 42px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-percent {
      font-family: var(--font-poppins);
      font-weight: 600;
      margin-left: 12px;

      &-change {
        margin-right: 16px;
        padding-top: 12px;
        width: 16px;
      }
    }

    &-label {
      margin-top: 8px;
    }
  }
}