#add-company-form {
  font-family: var(--font-Stolzl);
  .ant-form-item-label > label {
    height: auto;
  }
  .roleStatusLabel {
    color: #92929d;
    font-size: 14px;
    margin-right: 15px;
  }
  .rolePermissionsLabel {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
  }
  .anticon {
    vertical-align: 2px;
  }
  .ant-switch {
    background-color: #292932;
    &.ant-switch-checked {
      background-color: #bf1e2e;
    }
  }
  .ant-drawer {
    z-index: 9999;
  }
  .ant-tree {
    color: #92929d;
    background-color: transparent;
    margin: 10px 0 30px 0;
  }
  .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected,
  .ant-tree .ant-tree-node-content-wrapper:hover {
    background-color: transparent;
  }
  .ant-tree-checkbox-checked .ant-tree-checkbox-inner,
  .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
    background-color: #bf1e2e;
  }
  .ant-tree-show-line .ant-tree-switcher {
    background: transparent;
  }
  .ant-tree-checkbox-checked::after,
  .ant-tree-checkbox-checked .ant-tree-checkbox-inner,
  .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
  .ant-tree-checkbox:hover .ant-tree-checkbox-inner,
  .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
    border-color: #bf1e2e;
  }
  .ant-input {
    border-color: #292932 !important;
  }
  .ant-btn {
    padding: 4px 18px !important;
  }
  .ant-btn span {
    color: #292932 !important;
  }
  .drawerCloseBtn {
    background-color: #54545f !important;
  }
  .ant-upload-list.ant-upload-list-text{
    display: none;
  }

  .logo-img{
    padding-bottom: 10px;
    padding-left: 7px;
  }
}
@media only screen and (max-width: 992px) {
  .ant-drawer-right.ant-drawer-open {
    width: 100% !important;
  }
}
.tempImageWrap{
  position: relative;
  display: inline-block;
  .anticon-close {
    position: absolute;
    top: -12px;
    right: -12px;
    font-size: 15px;
    color: #fff;
    background-color: #292932;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50px;
  }
}

.duplicate-error{
  margin-bottom: 10px;
  margin-top: -17px;
}