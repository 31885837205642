.user-status-pending {
  color: var(--color-text-warn);
}

.user-status-active {
  color: var(--color-text-success);
}

.user-status-suspended {
  color: var(--color-text-error);
}