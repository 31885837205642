#add-role-form {
  font-family: var(--font-Stolzl);
  .ant-form-item-label > label {
    height: auto;
  }
  .roleStatusLabel {
    color: #92929d;
    font-size: 14px;
    margin-right: 15px;
  }
  .rolePermissionsLabel {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
  }
  .anticon {
    vertical-align: 2px;
  }
  .ant-switch {
    background-color: #292932;
    &.ant-switch-checked {
      background-color: #bf1e2e;
    }
  }
  .ant-drawer {
    z-index: 9999;
  }
  .ant-tree {
    color: #92929d;
    background-color: transparent;
    margin: 10px 0 30px 0;
  }
  .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected,
  .ant-tree .ant-tree-node-content-wrapper:hover {
    background-color: transparent;
  }
  .ant-tree-checkbox-checked .ant-tree-checkbox-inner,
  .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
    background-color: #bf1e2e;
  }
  .ant-tree-show-line .ant-tree-switcher {
    background: transparent;
  }
  .ant-tree-checkbox-checked::after,
  .ant-tree-checkbox-checked .ant-tree-checkbox-inner,
  .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
  .ant-tree-checkbox:hover .ant-tree-checkbox-inner,
  .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
    border-color: #bf1e2e;
  }
  .ant-input {
    border-color: #292932 !important;
  }
  .ant-btn {
    padding: 4px 18px !important;
  }
  .drawerCloseBtn {
    background-color: #54545f !important;
  }
}
.permissionsTable thead th {
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  border-color: #fff !important;
}
.permissionsTable tbody td {
  color: #fff !important;
  text-align: center;
}
.permissionsTable .table > :not(caption) > * > * {
  border-bottom-color: #292932 !important;
}
.permissionsTable {
  .ant-checkbox-inner::after {
    width: 0px !important;
    height: 18px !important;
    transform: rotate(45deg) scale(0.6) translate(-50%, -50%) !important;
    top: 20%;
    left: 0;
  }
  .table > :not(:first-child) {
    border-color: #292932 !important;
  }
  margin-bottom: 30px;
}
@media only screen and (max-width: 992px) {
  .ant-drawer-right.ant-drawer-open {
    width: 100% !important;
  }
}
