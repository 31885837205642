.user-status-pending {
  color: var(--color-text-warn);
}

.user-status-active {
  color: var(--color-text-success);
}

.user-status-suspended {
  color: var(--color-text-error);
}

.user-status-pending,
.user-status-active,
.user-status-suspended {
  font-family: var(--font-poppins);
  min-width: 60px;
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 0px !important;
}
.buyersSearchInput .ant-input {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  padding: 7px 15px !important;
}
.ant-input-search-button {
  height: 38px !important;
  background-color: #bf1e2e !important;
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
  font-size: 20px !important;
}
.ant-input-group-addon {
  background-color: transparent !important;
}
.ant-input-search-button svg {
  margin-right: 0 !important;
}
.ant-input-group-wrapper {
  width: unset !important;
  margin-top: -48px !important;
}
.AddTeamMemberBtn {
  width: auto !important;
  margin-right: 15px !important;
}
::placeholder {
  color: #1c1c24 !important;
}
@media screen and (max-width: 767px) {
  .ant-input-group-wrapper {
    margin-top: 0 !important;
  }
}
.form-check-input {
  width: 20px !important;
  height: 20px !important;
  &:checked {
    background-color: #bf1e2e !important;
    border-color: #bf1e2e !important;
  }
  &:focus {
    border-color: #bf1e2e !important;
    outline: 0;
    box-shadow: none !important;
  }
}

.ant-modal-root .ant-modal-confirm-body .ant-modal-confirm-title,
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn span,
.ant-modal-root .ant-modal-confirm-body .ant-modal-confirm-content,
.ant-modal-root .ant-modal-confirm-body .ant-modal-confirm-content{color: #fff;}
.ant-modal-root .ant-modal-confirm .ant-modal-confirm-btns{display: flex;align-items: center;justify-content: end;}
.ant-modal-confirm-btns button{background-color: #fff;font-size: 14px;height: 32px;border-radius: 6px;color: #000;min-width: 70px;}
.ant-modal-confirm-btns button span{color: #000;}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn{background-color: #ab0717;}

