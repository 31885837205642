#home {
  background: url('../../images/home.png') no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding: 120px;

  > .ant-layout-content {
    align-items: flex-start;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    padding: 120px 0;

    > .ant-layout-content {
      justify-content: center;
    }
  }
}
