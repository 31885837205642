@import "./variables";

@import "./form";

// Import styles
@import "@coreui/coreui-pro/scss/themes/dark";
@import "@coreui/coreui-pro/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css");

html body {
  background: var(--background-base);
  color: var(--color-text-base);
  font-family: var(--font-roboto);
}

@font-face {
  font-family: "Stolzl";
  src: url("../fonts/Stolzl-Medium.woff2") format("woff2"),
    url("../fonts/Stolzl-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Stolzl";
  src: url("../fonts/Stolzl-Regular.woff2") format("woff2"),
    url("../fonts/Stolzl-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Stolzl";
  src: url("../fonts/Stolzl-Bold.woff2") format("woff2"),
    url("../fonts/Stolzl-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background: var(--background-alt);
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background: var(--background-alt-2);
  border-radius: 20px;
}

body {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--color-text-base);
    font-family: var(--font-poppins);
    font-weight: 600;
  }

  h2 {
    font-size: 24px;
    line-height: 36px;
  }

  h4 {
    font-size: 16px;
    letter-spacing: 0.5px;
  }

  h5 {
    font-size: 16px;
    line-height: 24px;
  }

  a {
    color: var(--color-text-link);
  }

  .app-loading {
    align-items: center;
    background: var(--background-base);
    display: flex;
    min-height: 100vh;
    padding: 100px;
  }

  .ant-radio-group {
    border: 1px solid #44444f;
    border-radius: 4px;
    padding: 1px;

    .ant-radio-button-wrapper {
      background: transparent;
      border: 0;
      color: var(--color-text-alt-lighten);
      height: 26px;
      line-height: 26px;

      &:not(:first-child)::before {
        display: none;
      }

      &.ant-radio-button-wrapper-checked {
        background: #868686;
        border-radius: 0px;
        color: var(--background-body);
      }
      &:first-child {
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }
  }

  .ant-btn {
    border-radius: 100px;
    padding: 6px ​14px !important;
  }

  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):hover {
    color: var(--background-body);
    background: #868686;
    border-color: #868686;
  }

  .ant-modal {
    color: var(--color-text-base);
  }

  .ant-modal-content {
    background: var(--background-body);

    .ant-modal-body {
      padding: 30px;
    }

    .ant-modal-close {
      align-items: center;
      background: var(--color-text-base);
      border-radius: 100%;
      color: var(--color-text-alt);
      display: inline-flex;
      height: 30px;
      justify-content: center;
      right: -10px;
      top: -10px;
      width: 30px;
    }
  }

  .ant-dropdown-trigger {
    align-items: center;
    display: inline-flex;
    justify-content: center;
  }

  .ant-table-filter-dropdown {
    background: var(--background-alt);

    &-btns {
      border-top: 1px solid #44444f;
    }
  }

  .ant-table-filter-trigger-container,
  .ant-table-filter-trigger-container-open,
  .ant-table-filter-trigger-container:hover,
  .ant-table-thead
    th.ant-table-column-has-sorters:hover
    .ant-table-filter-trigger-container:hover {
    background: transparent !important;
  }

  .ant-table-filter-trigger-container-open .ant-table-filter-trigger,
  .ant-table-filter-trigger:hover {
    color: var(--color-text-base);
  }

  .ant-dropdown-menu {
    background: var(--background-alt);
    border-radius: 6px;

    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title,
    .ant-dropdown-menu-item > a {
      color: var(--color-text-base);
      font-family: var(--font-poppins);
      line-height: 21px;

      &:hover {
        background: transparent;
        color: #dedee4;
      }
    }

    .ant-dropdown-menu-item-selected,
    .ant-dropdown-menu-submenu-title-selected,
    .ant-dropdown-menu-item-selected > a,
    .ant-dropdown-menu-submenu-title-selected > a {
      background: transparent;
    }
  }

  .ant-drawer-content {
    background: var(--background-body);
    border-left: 1px solid #44444f;

    .ant-drawer-header {
      background: var(--background-body);
      border-bottom: 1px solid #44444f;
      border-radius: 0;

      .ant-drawer-title {
        color: var(--color-text-base);
        font-family: var(--font-poppins);
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.075px;
        line-height: 27px;
      }

      .ant-drawer-close {
        color: var(--color-text-alt);
      }
    }
  }

  .ant-empty-normal {
    color: var(--color-text-base);
  }
}

#root {
  .ant-layout-header {
    background: var(--background-body);
    border-bottom: 1px solid #44444f;
    height: 70px;
    line-height: 70px;
    padding-left: 20px;
    padding-right: 20px;

    > .ant-row {
      height: 70px;
    }

    .layouts-logged-in-logo {
      align-items: center;
      display: flex;
    }
  }

  .ant-layout-footer {
    background: var(--background-base);
    color: var(--color-text-alt);
    font-size: 12px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    padding: 0 50px;
  }

  .ant-table-content {
    overflow: auto;
  }

  @media only screen and (max-width: 991px) {
    .ant-layout-header {
      height: 86px;
      line-height: 56px;

      > .row {
        background: inherit;
        left: 0;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 12px;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 1000;
      }
    }
  }

  > .ant-layout > .ant-layout-content {
    margin: 0 auto;
    max-width: calc(100vw - 16px);
  }
}

.text-center {
  text-align: center;
}

.text-small {
  font-size: 12px;
}

.color-base {
  color: var(--color-text-base);
}

.color-alt {
  color: var(--color-text-alt);
}

.color-alt-lighten {
  color: var(--color-text-alt-lighten);
}

.padding-bottom-1 {
  padding-bottom: var(--space-1);
}

.padding-bottom-1_5 {
  padding-bottom: var(--space-1_5);
}

.padding-bottom-2 {
  padding-bottom: var(--space-2);
}

.margin-bottom-5 {
  margin-bottom: var(--space-5);
}

.margin-left-0_8 {
  margin-left: var(--space-0_8);
}

.ds-title {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 36px;
  text-align: center;
}

.ds-header {
  font-family: var(--font-poppins);
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.text-medium-emphasis {
  color: #92929e !important;
}

.ant-table-tbody select {
  background: #0f6bff url(../images/svg/arrow-down.svg) no-repeat right 10px
    center;
  background-size: 15px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 7px 30px 7px 10px;
  border: none;
  border-radius: 100px;
  font-weight: 700;
  font-size: 12px;
  option {
    background-color: #fefeff;
    color: #44444f;
  }
}
.leadStatusSelectBox {
  padding: 7px 20px;
  border-radius: 50px;
  color: #fff !important;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
}
.leadActivityCount {
  font-family: var(--font-Stolzl);
  font-weight: 700;
  font-size: 20px;
}
.leadActivityTitle {
  font-family: var(--font-poppins);
  font-weight: 500;
  font-size: 16px;
  color: #83818e;
}
.border-end-primary {
  border-right-color: #0f6bff !important;
}
.border-end-yellow {
  border-right-color: #ff984a !important;
}
.border-end-success {
  border-right-color: #00ac67 !important;
}
.bg-primary-gradient {
  background: linear-gradient(#0f6bff, #0140a5);
}
.bg-warning-gradient {
  background: linear-gradient(#ff984a, #cf6a1e);
}
.bg-danger-gradient {
  background: linear-gradient(#db2738, #ab0717);
}
.bg-success-gradient {
  background: linear-gradient(#00ac67, #01844f);
}
.leadActivityCard {
  box-shadow: none;
}
.leadActivityCard .card-body {
  padding-left: 20px;
}
.chartWidgetCard {
  border-radius: 0;
  font-weight: 500;
}
.chartWidgetCount {
  font-size: 26px;
  font-weight: 700;
  color: #fefeff;
  font-family: var(--font-Stolzl);
  margin-right: 5px;
}
.chartWidgetRatio {
  font-family: var(--font-Stolzl);
  font-weight: 400;
  font-size: 16px;
}
.leadStatusWrap,
.leadStatusMain {
  display: flex;
  align-items: center;
}
.leadStatusMain {
  justify-content: space-between;
}
.leadStatusLabel,
.common-drawer-info-title,
.leadHistoryTime,
.leadHistoryStatus,
.buyersLastLogin .title {
  font-family: var(--font-roboto);
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
  color: #83818e;
  margin-right: 10px;
}
.offers-details-data-group {
  padding-bottom: 15px;
}
.leadStatusOfferPrice {
  color: #83818e;
}
.leadStatusOfferPrice b,
.common-drawer-info-value {
  color: #fefeff;
  font-weight: 700;
}
.offers-details-data-group .ant-btn.button-secondary,
.ant-btn.ant-btn-primary.reset {
  border: 2px solid #83818e !important;
  color: #fefeff !important;
  padding: 10px 25px !important;
  background-color: transparent !important;
  transition: all 0.5s ease;
}
.ant-btn.ant-btn-primary.reset {
  color: #83818e !important;
  margin-left: 10px;
}
.offers-details-data-group .ant-btn.button-secondary:hover {
  background-color: #83818e !important;
}
.commonDrawerGroup {
  display: flex;
  overflow-wrap: break-word;
  margin-bottom: 10px;
}
.commonDrawerGroup .common-drawer-info-value {
  margin-bottom: 0;
}
.leadHistoryWrap {
  margin-top: 20px;
}
.leadHistoryRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  position: relative;
  margin-bottom: 20px;
}
.leadHistoryRow::after {
  content: "";
  position: absolute;
  top: 20px;
  height: 30px;
  left: 11px;
  width: 1px;
  display: block;
  background-color: #35353e;
  z-index: -1;
}
.leadHistoryStatus {
  position: relative;
  padding-left: 30px;
}
.leadHistoryStatus::before {
  content: "";
  position: absolute;
  height: 22px;
  width: 22px;
  background-color: #fefeff;
  display: inline-block;
  border-radius: 100px;
  left: 0;
}
.leadHistoryTime {
  font-family: var(--font-roboto);
  font-weight: 500;
  color: #83818e;
}
.leadHistoryWrap .leadHistoryRow:last-child:after {
  display: none;
}
.leadHistoryStatus {
  color: #fefeff;
}
.boxTitle {
  font-size: 20px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.buyersData .commonDrawerGroup {
  margin-bottom: 20px;
}
.buyersLastLogin .ant-col {
  display: flex;
  align-items: center;
}
.buyersLastLogin h2 {
  margin-bottom: 0;
}
.buyersLastLogin .title {
  margin-left: 10px;
}
.buyersLastLogin {
  padding-bottom: 20px;
  border-bottom: 1px solid #35353e;
  margin-bottom: 30px;
}
.common-table-wrapper {
  margin-top: 20px;
  overflow-x: auto;
}
@media (max-width: 768px) {
  .leadHistoryRow::after {
    height: 50px;
  }
}
.financeActivity {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  .financeActivityBox {
    flex: 0 0 20%;
    max-width: 20%;
    @media (max-width: 1280px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media (max-width: 600px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
    padding-left: 15px;
    padding-right: 15px;
    .chartWidgetCard {
      background-color: #1c1c24;
      box-shadow: none;
    }
  }
}

.chartWidgetCard {
  min-height: 220px;
}
.Innerform {
  .inputformField[type="password"] {
    padding-right: 55px;
  }
  .inputformPasswordToggle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    background-image: url(../images/svg/eyes.svg);
    background-repeat: no-repeat;
    background-position: 97% center;
    background-size: 20px;
    height: 20px;
    width: 20px;
    cursor: pointer;
    &.icon-white {
      filter: brightness(0) invert(1);
    }
  }
  .inputformPasswordToggle.show {
    background-image: url(../images/svg/eyes-show.svg);
  }
}
.sortIcon {
  background-image: url(../images/sort-icon.svg);
  width: 12px;
  height: 12px;
  display: inline-block;
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 0 -1px 5px;
  cursor: pointer;
  &.asc {
    background-image: url(../images/sort-asc.svg);
  }
  &.desc {
    background-image: url(../images/sort-desc.svg);
  }
}
.sortIcon .update-profile-title {
  margin-bottom: 25px;
}
.input-col {
  display: block;
  margin-bottom: 25px;
  font-family: var(--font-Stolzl);
  font-weight: 400;
}
.input-label {
  color: #92929d;
  font-size: 14px;
  display: block;
  line-height: 1.2;
  margin-bottom: 5px;
}
.input-text,
.input-select,
.input-btn {
  display: block;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 14px;
  outline: none;
  border: none;
  width: 100%;
  min-width: 150px;
  resize: none;
  background-color: #292932;
}
.input-select {
  background-image: url(../images/arrow-down.svg);
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 12px;
  appearance: none;
  -webkit-appearance: none;
}
.input-text,
.input-select {
  color: #fff;
}
.input-btn {
  color: #fff;
  font-weight: 600;
  background-color: #ab0717;
  text-transform: uppercase;
}
.input-btn:hover {
  color: #fff;
}
::placeholder {
  color: #92929d !important;
}
tbody tr:hover {
  background-color: #292932;
}
.notfound-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.notfound-inner {
  position: relative;
}
.notfound-inner h2 {
  font-size: 200px;
  color: #fff;
  margin-bottom: 15px;
  line-height: 1;
  text-align: center;
  font-weight: 800;
  letter-spacing: 1px;
}
.notfound-inner span {
  font-size: 24px;
  line-height: 1;
  font-weight: 600;
  margin: 0;
  color: #fff;
  text-align: center;
  padding: 10px 0;
}
.notfound-inner p {
  font-size: 21px;
  line-height: 1.4;
  font-weight: 400;
  color: #ffffffa6;
  text-align: center;
  max-width: 75%;
  margin: 30px auto;
}
@media only screen and (max-width: 992px) {
  .offers-page-filters {
    margin: 0 0 15px 0;
  }
  .filter-btn {
    margin-bottom: 10px;
  }
  .ant-drawer-right.ant-drawer-open {
    width: 100% !important;
  }
}
.inquiryFormHeader .commonDrawerGroup {
  margin-bottom: 0;
}
.leadActivityBox {
  &.box-1 {
    border-right: 5px solid #0f6bff;
  }
  &.box-2 {
    border-right: 5px solid #ff984a;
  }
  &.box-3 {
    border-right: 5px solid #00ac67;
  }
  .small {
    font-size: 14px;
  }
}
.fs-5 {
  font-size: 20px !important;
}
.chartjs-tooltip-header-item,
.chartjs-tooltip-body-item {
  font-size: 12px;
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: #1c1c24;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  font-size: 17px;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: none !important;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #595965;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #595965;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}
.react-calendar__tile:disabled {
  background-color: #595965;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #595965;
}
.react-calendar__tile--now {
  background: #bf1e2e;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #bf1e2e;
}
.react-calendar__tile--hasActive {
  background: #bf1e2e;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #595965;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #bf1e2e;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #595965;
}

.react-daterange-picker {
  display: inline-flex;
  position: relative;
}
.react-daterange-picker,
.react-daterange-picker *,
.react-daterange-picker *:before,
.react-daterange-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-daterange-picker--disabled {
  background-color: #595965;
  color: white;
}
.react-daterange-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  border: thin solid gray;
}
.react-daterange-picker__inputGroup {
  min-width: auto;
  height: 100%;
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
}
.react-daterange-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}
.react-daterange-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}
.react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
.react-daterange-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-daterange-picker__inputGroup__input:invalid {
  background: #ffffff1a;
}
.react-daterange-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}
.react-daterange-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}
.react-daterange-picker__button:enabled {
  cursor: pointer;
}
.react-daterange-picker__button:enabled:hover
  .react-daterange-picker__button__icon,
.react-daterange-picker__button:enabled:focus
  .react-daterange-picker__button__icon {
  stroke: #0078d7;
}
.react-daterange-picker__button:disabled .react-daterange-picker__button__icon {
  stroke: #6d6d6d;
}
.react-daterange-picker__button svg {
  display: inherit;
  stroke: #fff;
}
.react-daterange-picker__calendar {
  width: 350px;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}
.react-daterange-picker__calendar--closed {
  display: none;
}
.react-daterange-picker__calendar .react-calendar {
  border-width: thin;
}
.react-daterange-picker * {
  font-family: inherit;
}
.react-daterange-picker--closed {
  border-radius: 4px;
}
.react-daterange-picker--closed .react-daterange-picker__wrapper {
  border-radius: 4px;
}
.react-daterange-picker--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.react-daterange-picker--open .react-daterange-picker__wrapper {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.react-daterange-picker__wrapper {
  background: #292932;
  border: 0;
  border-radius: 4px;
  padding: 5px;
  font-size: 1.25em;
}
.react-daterange-picker__inputGroup {
  min-width: auto;
  flex-grow: 1;
  display: flex;
  padding: 2px;
  align-items: baseline;
}
.react-daterange-picker__inputGroup__divider {
  padding: 1px;
}
.react-daterange-picker__inputGroup__input {
  -moz-appearance: textfield;
}
.react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
.react-daterange-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-daterange-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}
.react-daterange-picker__calendar {
  border-radius: 4px;
  box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
  border-top-left-radius: 0;
}
.react-daterange-picker__calendar .react-calendar {
  border-top-left-radius: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: none;
}
.react-daterange-picker__clock {
  border-radius: 4px;
  box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
  font-family: inherit;
  width: 220px;
  height: 220px;
  padding: 20px;
  border: 0;
  border-top-left-radius: 0;
}
.react-daterange-picker__clock .react-clock__second-hand {
  transition: transform cubic-bezier(0.68, 0, 0.27, 1.55) 0.2s;
}
.react-daterange-picker__clock .react-clock__second-hand__body:before {
  width: 5px;
  height: 5px;
}
.react-daterange-picker__clock .react-clock__second-hand__body:after {
  width: 15px;
  height: 15px;
}
.react-daterange-picker--open {
  border-radius: 4px;
  border-bottom-left-radius: 0;
}
.react-daterange-picker--open .react-daterange-picker__wrapper {
  border-radius: 4px;
  border-bottom-left-radius: 0;
}
.ck.ck-toolbar {
  background: #292932 !important;
  border: none;
  padding: 0;
}
.ck.ck-editor__main > .ck-editor__editable {
  background: #292932 !important;
  color: #fff !important;
  border: 0 !important;
}
.ck-reset_all :not(.ck-reset_all-excluded *),
.ck.ck-reset_all {
  color: #fff !important;
}
.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
  background: #595965 !important;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
  opacity: 0 !important;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}
.deals-search-clear-btn {
  font-size: 20px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  color: #a0a0a0;
}
.copy-landing-page {
  display: flex;
  .copy-landing-page-text {
    padding: 6px 10px;
    display: block;
    background-color: #535353;
    border-radius: 5px;
    margin-right: 10px;
    overflow: hidden;
    white-space: nowrap;
    width: 250px;
    text-overflow: ellipsis;
  }
  .copy-landing-page-btn {
    color: #fff;
    background-color: #bf1e2e;
    border-radius: 5px;
    display: inline-block;
    cursor: pointer;
    font-size: 17px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #dc2335;
    }
  }
}
.ant-drawer-close .anticon-close {
  align-items: center;
  background: var(--color-text-base);
  border-radius: 100%;
  color: var(--color-text-alt);
  display: inline-flex;
  height: 30px;
  justify-content: center;
  width: 30px;
}
@media only screen and (max-width: 575px) {
  .header-cars-text {
    font-size: 14px;
  }
}
#qrNode {
  background-color: #fff;
  padding: 16px;
  display: inline-block;
  border-radius: 4px;
}
.formCopyLinkWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #292932;
  border-radius: 4px;
  font-size: 17px;
  margin-bottom: 15px;
  padding-left: 10px;
  overflow: hidden;
  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  button {
    background-color: #00ac67;
    color: #fff;
    outline: none;
    padding: 5px 11px;
    border-radius: 2px;
    &:hover {
      background-color: #018852;
    }
    transition: all 0.4s ease-in-out;
  }
}
.notificationboxBtn {
  background-color: transparent;
  color: #fff;
  line-height: 1;
  font-size: 25px;
  position: relative;
  .dot {
    top: 0;
    right: 0;
    height: 7px;
    width: 7px;
    border-radius: 50px;
    background-color: #ff0000;
    position: absolute;
  }
}
.notificationboxMain {
  position: relative;
  margin-right: 15px;
}
.notificationbox {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 9;
  top: 125%;
  right: inherit;
  background-color: #1c1c24;
  padding: 20px;
  box-shadow: 0px 10px 15px -10px #44444f;
  border-radius: 10px;
  border: 1px solid #484848;
  opacity: 0;
  visibility: hidden;
  width: 300px;
  line-height: normal;
  left: 50%;
  transform: translateX(-50%);
  @media screen and (max-width: 992px) {
    transform: translateX(-70%);
    top: 95%;
  }
}
.notificationboxMain .notificationbox.show {
  opacity: 1;
  visibility: visible;
}
.notifiTitle {
  font-size: 18px;
  font-weight: 700;
}
.notification-close {
  border: none;
  outline: none;
  box-shadow: none;
  color: #747b87;
  font-size: 22px;
  transition: all 0.4s ease-in-out;
  background: transparent;
  position: absolute;
  top: 10px;
  right: 10px;
  line-height: normal;
}
.notifibox {
  display: block;
  margin: 13px 0;
  max-height: 35vh;
  padding-right: 10px;
  overflow: auto;
}
.notifibox ul {
  padding: 0;
  margin: 0;
}
.notifibox ul li {
  color: #fff;
  border-bottom: 1px solid #4e4e4e;
  padding-bottom: 4px;
  margin-bottom: 7px;
  p {
    font-size: 14px;
    margin-bottom: 10px;
  }
  button {
    background-color: #bf1e2e;
    padding: 5px;
    border-radius: 45px;
    line-height: 1;
    font-size: 12px;
  }
  i {
    font-size: 14px;
    font-weight: 300;
  }
  &:last-child {
    border-bottom: none;
  }
}
.notifibox ul li span {
  display: block;
  font-size: 12px;
  color: #747b87;
  font-weight: 400;
  margin-top: 7px;
}
.viewmore {
  cursor: pointer;
  color: #bf1e2e;
  font-size: 14px;
  font-weight: 500;
}
.buyer-star-rating {
  display: inline-block;
  text-align: center;
  margin-bottom: 20px;
  span {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 35px;
    color: #92929e;
    &.selected {
      color: #ffd000;
    }
  }
}
.buyer-star-rating-sm {
  display: inline-block;
  span {
    display: inline-block;
    margin-left: 3px;
    margin-right: 3px;
    font-size: 20px;
    color: #92929e;
    &.selected {
      color: #ffd000;
    }
  }
}
.btn-sm {
  border-radius: 50px;
  background-color: #ab0717;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  padding: 8px 8px 5px;
  transition: all 0.4s ease-in-out;
}
@media screen and (max-width: 992px) {
  body h2,
  body .h2 {
    font-size: 20px;
    line-height: 1.1;
  }
}
.thank-you-wrap {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow-y: auto;
}
.thank-you-con img {
  width: 271px;
  margin: auto auto 60px;
}
.thank-you-con h1 {
  font-size: 106px;
  line-height: 1;
  font-weight: 700;
  display: block;
  margin-bottom: 30px;
}
.thank-you-con p {
  font-size: 30px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  line-height: 1.2;
  margin-bottom: 106px;
}
@media screen and (max-width: 992px) {
  .thank-you-con img {
    width: 251px;
    margin: auto auto 40px;
  }
  .thank-you-con h1 {
    font-size: 75px;
    margin-bottom: 20px;
  }
  .thank-you-con p {
    font-size: 24px;
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 568px) {
  .thank-you-con img {
    width: 200px;
    margin: auto auto 30px;
  }
  .thank-you-con h1 {
    font-size: 55px;
  }
  .thank-you-con p {
    font-size: 18px;
    margin-bottom: 50px;
  }
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 42px !important;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 42px !important;
}
.ant-select-arrow {
  display: none !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent !important;
  border: none !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px !important;
}
.ant-select-selection-item,
.ant-select-selection-search-input,
.ant-select-item,
.ant-select-dropdown,
.ant-select-item-option-active:not(.ant-select-item-option-disabled),
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #fff !important;
}
.ant-select-dropdown {
  background-color: #1c1c24 !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #292932 !important;
}
