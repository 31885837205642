.common-table-wrapper {
  background: var(--background-body);
  // border-radius: var(--radius-main);
  padding: var(--space-box);

  &.table-row-click {
    .ant-table-row {
      cursor: pointer;

      &:hover {
        background: var(--background-alt);
      }
    }
  }

  .ant-table {
    background: transparent;
  }

  .ant-table-thead tr {
    // background: var(--background-alt);
    // border-radius: var(--radius-small);
    height: 38px;

    .ant-table-cell {
      background: transparent !important;
      border-bottom: 1px solid #35353e;
      color: var(--color-text-alt);
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.8px;
      line-height: 16px;
      padding-bottom: 11px;
      padding-top: 11px;
      // text-transform: uppercase;

      // &:first-child {
      //   border-radius: var(--radius-small) 0 0 var(--radius-small);
      // }

      // &:last-child {
      //   border-radius: 0 var(--radius-small) var(--radius-small) 0;
      // }
    }

    th {
      font-family: var(--font-Stolzl);
    }
  }

  .ant-table-tbody tr td {
    &:first-child {
      font-weight: 700;
    }
  }

  td.ant-table-cell {
    background: transparent !important;
    border-bottom: 1px solid #35353e;
    color: var(--color-text-base);
    font-family: var(--font-poppins);
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;

    &.table-payout {
      // color: var(--color-text-success);
      font-weight: 700;
    }
  }

  .ant-pagination.ant-table-pagination {
    // border-top: 1px solid #44444F;
    margin: 0 var(--space-box-inverted);
    padding: 28px 36px 10px;
    align-items: center;
    .break {
      display: inline-block;
      margin-right: 8px;
      a {
        font-weight: 700;
        font-size: 22px;
        color: #7c7a87;
      }
    }
  }

  .ant-pagination-total-text {
    color: var(--color-text-alt);
    flex: auto;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    button {
      border-color: #44444f;
      // border-radius: 8px;
    }

    .ant-pagination-item-link {
      background: transparent;
      color: var(--badge-text-color);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      border: 0;
    }

    &:hover {
      button {
        border-color: #44444f;
      }

      .ant-pagination-item-link {
        color: var(--color-text-alt);
      }
    }
  }

  .ant-pagination-item {
    background: transparent;
    border-color: transparent !important;
    font-family: var(--font-Stolzl);
    font-weight: 700;
    min-width: 15px;
    color: #7c7a87;

    a {
      color: var(--color-text-alt);
      transition: color 0.4s;

      &:hover {
        color: var(--color-text-link);
      }
    }

    &-active {
      background: transparent;
      color: #fff;
      a {
        color: var(--color-text-base);
      }

      &:hover {
        a {
          color: var(--color-text-link-hover);
        }
      }
    }
  }

  .common-table-offer-status {
    > span {
      width: 82px;
    }
  }

  .input-select {
    width: auto;
  }
}
.ant-pagination-disabled {
  opacity: 0.5;
}
