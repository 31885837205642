#root {
  .layouts-logged-in {
    min-height: 100vh;

    .mobile-sider-blocker {
      background: rgba(0, 0, 0, 0.7);
      bottom: 0;
      content: '';
      opacity: 0;
      pointer-events: none;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      transition: opacity 0.3s;
    }

    @media only screen and (max-width: 991px) {
      &.sider-visible {
        .mobile-sider-blocker {
          pointer-events: all;
          opacity: 1;
          z-index: 8;
        }
      }

      .ant-layout-header {
        .layouts-logged-in-logo {
          text-align: center;
          padding-left: 30px;
        }
      }
      padding-bottom: 38px;
    }
  }

  .ant-layout-sider {
    background: var(--background-body);

    &-below {
      bottom: 0;
      left: 0;
      position: fixed;
      top: 56px;
      z-index: 1100;

      .ant-layout-sider-children {
        position: absolute;
        right: 0;
        width: 210px;
      }
    }

    &-children {
      .ant-menu {
        background: var(--background-body);
        padding-top: 20px;
      }

      .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
        border-right: 0;
      }

      .ant-menu-item {
        margin-bottom: 16px;

        &:hover,
        &:active {
          background: transparent;
        }

        &-group {
          &-title {
            color: var(--color-text-dim);
            font-family: var(--font-poppins);
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 1px;
            line-height: 21px;
            margin-bottom: 20px;
            padding-left: 20px;
            padding-right: 20px;
            text-transform: uppercase;
          }

          &-list {
            .ant-menu-item {
              padding: 0 20px;
            }
          }

          + .ant-menu-item-group {
            margin-top: 20px;
          }
        }

        a {
          align-items: center;
          color: var(--color-text-alt);
          display: flex;
          font-family: var(--font-poppins);
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0.1px;
          line-height: 21px;
          transition: color 0.4s;

          &:hover {
            color: var(--color-text-base);
          }

          svg {
            margin-right: 22px;
          }
        }
      }
    }

    &-zero-width-trigger {
      align-items: center;
      background: transparent;
      display: flex;
      height: 36px;
      justify-content: center;
      line-height: 36px;
      left: 10px;
      position: fixed;
      top: 10px;
      width: 36px;
    }
  }

  .layout-content {
    background: var(--background-base);
    color: var(--color-text-base);
    padding: 30px 40px;

    @media only screen and (max-width: 991px) {
      padding: 16px;
    }
  }
}