.field-loader {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000a8;
  z-index: 9;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.inquiryFormHeader {
  border-bottom: 1px solid #35353e;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 992px) {
  .sendOfferBtn {
    width: 100%;
    margin-bottom: 15px;
  }
}
