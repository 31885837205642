#inquiry {
  background: url("../../images/inquiry.png") no-repeat;
  background-size: cover;
  background-attachment: fixed;
  @media only screen and (max-width: 991px) {
    background-image: none;
    .inquiryFormFooter br {
      display: none;
    }
  }
  > .ant-layout-content {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    padding: 50px 0;

    @media only screen and (max-width: 991px) {
      padding: 25px 15px;
    }

    .inquiry-container {
      //max-width: 390px;
      width: 700px;
    }

    .ant-col .ant-form-item-control {
      width: 300px;
      margin-right: 20px;
    }

    form {
      background: var(--background-body);
      padding: 60px;

      @media only screen and (max-width: 991px) {
        padding: 40px;
      }
    }
  }
}
.inquiryFormHeader {
  .ds-title {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 5px;
    @media only screen and (max-width: 991px) {
      font-size: 34px;
    }
  }
  p {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .buyers_id {
      display: none;
    }
    margin-bottom: 0;
    &:last-child {
      margin: 20px 0 0 0;
      font-size: 14px;
    }
    .referral-user {
      margin-left: -3px !important;
      font-size: 16px;
    }
  }
  border-bottom: 1px solid #35353e;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.inquiryForm {
  h4 {
    font-size: 18px;
    margin-bottom: 15px;
    &:last-child {
      margin-top: 10px;
    }
  }
  .inquiryFormFooter {
    margin-top: 10px;
    padding: 20px 0;
    border-top: 1px solid #35353e;
  }
}
.title {
  font-size: 35px;
}
