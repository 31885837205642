.common-form-splash{
  margin: auto !important;
}
.field-loader {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000a8;
  z-index: 9;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
